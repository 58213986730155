import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/stickyMenu.js";

export default function StickyMenu() {
  const [scrolled, setScrolled] = useState(false);
  const stickyMenu = ["sticky-menu"];

  const handleScroll = () => {
    if (window.scrollY > 160) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  if (scrolled) {
    stickyMenu.push("sticky");
  }

  return (
    <Styles>
      {/* Sticky Menu */}
      <section className={stickyMenu.join(" ")}>
        <Container>
          <Row>
            <Col md="3">
              <div className="logo">
                <Link to={process.env.PUBLIC_URL + "/"}>
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/logo.png"}
                    alt=""
                  />
                </Link>
              </div>
            </Col>
            <Col md="9">
              <div className="main-menu-box">
                <div className="menu-box d-flex justify-content-between">
                  <ul className="nav menu-nav">
                    <li className="nav-item active">
                      <Link
                        className="nav-link"
                        to={process.env.PUBLIC_URL + "/"}
                      >
                        Home
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to={process.env.PUBLIC_URL + "/about-us"}
                      >
                        About Us
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to={process.env.PUBLIC_URL + "/academics"}
                      >
                        Academics
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to={process.env.PUBLIC_URL + "/contact-us"}
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to={process.env.PUBLIC_URL + "/gallery"}
                      >
                        Gallery
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to={process.env.PUBLIC_URL + "/alumni"}
                      >
                        Alumni
                      </Link>
                    </li>
                  </ul>
                  <div className="apply-btn">
                    <Link to={process.env.PUBLIC_URL + "/apply"}>
                      <i className="las la-clipboard-list"></i>Apply Now
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}
