import React from "react";
// import ContentLoader from "react-content-loader";
import Loader from "react-loader-spinner";
import Header from "./Header";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default function Loading() {
  return (
    <div className="main-wrapper">
      {/* Header */}
      <Header />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#ecebeb",
          height: "100vh",
        }}
      >
        {/* <Loader type="Oval" color="#1DA1F2" height={50} width={50} /> */}
        <Loader type="Circles" color="#1DA1F2" height={80} width={80} />
      </div>
      {/* <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // height: "100vh",
      }}
    >
      <ContentLoader
        speed={3}
        width={600}
        height={240}
        viewBox="0 0 400 160"
        backgroundColor="#a0d8fa"
        // foregroundColor="#1DA1F2"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
        <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
        <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
      </ContentLoader>
    </div> */}
    </div>
  );
}
