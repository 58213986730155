import styled from "styled-components";
import { colors } from "../element/elements.js";

export const Styles = styled.div`
  .mobile-menu-area {
    background: ${colors.bg1};
    display: none;

    .mb-logo-area {
      padding: 15px 0;
      .mb-logo-box {
        .hm-button {
          margin-right: 40px;
          position: relative;
          &:before {
            position: absolute;
            content: "";
            background: ${colors.text1};
            width: 2px;
            height: 35px;
            top: -2px;
            right: -20px;
          }
          a#mb-sidebar-btn {
            i {
              font-size: 30px;
              color: ${colors.blue};
            }
          }

          @media (max-width: 480px) {
            margin-top: 0;
            margin-right: 20px;
            &:before {
              content: none;
            }
          }
        }
        .mb-logo {
          align-self: center;

          a {
            img {
              max-width: 150px;
              @media (max-width: 480px) {
                max-width: 120px;
              }
            }
          }
        }
      }
    }

    .apply-btn {
      position: absolute;
      // right: 20px;
      right: 7%;
      align-self: center;

      a {
        font-size: 10px;
        color: #ffffff;
        background: ${colors.gr_bg};
        display: inline-block;
        width: 70px;
        height: 25px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
        padding: 4px;
        border-radius: 0 5px 5px 0;
        position: relative;

        i {
          position: absolute;
          font-size: 16px;
          left: -23px;
          top: 0;
          padding: 4px;
          width: 25px;
          height: 100%;
          border-radius: 5px 0 0 5px;
          background-color: ${colors.blue2};
        }

        &:hover {
          background: ${colors.gr_bg2};
        }
      }

      @media (max-width: 300px) {
        display: none;
      }
    }

    @media (max-width: 767px) {
      display: block;
    }
  }

  .mb-sidebar {
    background: #ffffff;
    height: 100%;
    width: 320px;
    position: fixed;
    top: 0;
    left: -320px;
    overflow-y: auto;
    z-index: 9999;
    transition: all 400ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    display: none;
    .mb-sidebar-heading {
      background: ${colors.gr_bg};
      padding: 25px;
      h5 {
        color: #ffffff;
        text-transform: uppercase;
      }
      a#close-mb-sidebar {
        i {
          font-size: 22px;
          color: #ffffff;
        }
      }
    }
    .mb-sidebar-menu {
      padding: 25px;
      .mb-menu-item {
        border-top: 1px solid ${colors.border1};
        &:last-child {
          border-bottom: 1px solid ${colors.border1};
        }

        .mb-menu-content {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.2s ease-in-out;

          ul {
            li {
              border-top: 1px solid ${colors.border1};
              a {
                font-size: 13px;
                color: ${colors.black2};
                display: block;
                padding: 10px 0;
                padding-left: 15px;
                &:hover {
                  color: ${colors.blue};
                }
              }
            }
          }
        }

        .mb-menu-content.show {
          max-height: 100%;
        }
      }
    }

    @media (max-width: 767px) {
      display: block;
    }

    @media (max-width: 480px) {
      max-width: 275px;
    }
  }

  .mb-sidebar.opened {
    left: 0 !important;
  }

  .mb-sidebar-overlay {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: block;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1111;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease;
  }

  .mb-sidebar-overlay.visible {
    visibility: visible;
    opacity: 1;
  }
`;
