import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import loadable from "@loadable/component";
import ScrollToTop from "./helper/ScrollToTop";
import { GlobalStyle } from "./components/common/styles/global.js";
import Loading from "./components/Loading";

const Home = loadable(() => import(/* webpackPreload: true */ "./Home"), {
  fallback: <Loading />,
});
const About = loadable(
  () => import(/* webpackPreload: true */ "./pages/about/About"),
  {
    fallback: <Loading />,
  }
);
const Academics = loadable(
  () => import(/* webpackPreload: true */ "./pages/academics/Academics"),
  {
    fallback: <Loading />,
  }
);
const Gallery = loadable(
  () => import(/* webpackPreload: true */ "./pages/gallery/Gallery"),
  {
    fallback: <Loading />,
  }
);
const Alumni = loadable(
  () => import(/* webpackPreload: true */ "./pages/alumni/Alumni"),
  {
    fallback: <Loading />,
  }
);
const Apply = loadable(
  () => import(/* webpackPreload: true */ "./pages/apply/Apply"),
  {
    fallback: <Loading />,
  }
);
const Contact = loadable(
  () => import(/* webpackPreload: true */ "./pages/contact/Contact"),
  {
    fallback: <Loading />,
  }
);
const PageNotFound = loadable(
  () => import(/* webpackPreload: true */ "./pages/404/PageNotFound"),
  {
    fallback: <Loading />,
  }
);

function App() {
  return (
    <Router>
      <GlobalStyle />
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about-us" component={About} />
        <Route exact path="/academics" component={Academics} />
        <Route exact path="/gallery" component={Gallery} />
        <Route exact path="/alumni" component={Alumni} />
        <Route exact path="/apply" component={Apply} />
        <Route exact path="/contact-us" component={Contact} />
        <Route component={PageNotFound} />
      </Switch>
    </Router>
  );
}

export default App;
